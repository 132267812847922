<template>
  <div class="container">
    <iframe
      id="otovo-iframe"
      :src="`https://solar.otovo.com/${LANG}/search?context=iframe&utm_source=Elskling&utm_campaign=Elskling-autumn-23&theme=default`"
      class="otovo-address-search"
      title="otovo-address-search"
      loading="lazy"
      height="140px"
      width="100%"
      data-qa="iframe"
    />
  </div>
</template>

<script>
  export default {
    name: 'Otovo',
    metaInfo () {
      return {
        script: [
          {
            src: 'https://solar.otovo.com/js/search.js',
            async: true,
            defer: true
          }
        ]
      }
    }
  }
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: rem(1090px + (16px * 2));
  margin-right: auto;
  margin-left: auto;
}

.otovo-address-search {
  left: 0;
  border: 0;
  overflow: hidden;
}
</style>
